import React from 'react';
import { graphql } from 'gatsby';
import CardList, { CardListFragment } from '../components/CardList';
import Layout from '../components/Layout';
import { AllMarkdownRemark } from '../types/query';

type PageContext = {
  tag: string;
};

type QueryData = AllMarkdownRemark<CardListFragment>;

type Props = {
  data: QueryData;
  pageContext: PageContext;
};

const TagTemplate: React.FC<Props> = props => {
  const { data, pageContext } = props;
  return (
    <Layout title={`#${pageContext.tag}`} showTagMenu>
      <CardList items={data.allMarkdownRemark.edges} />
    </Layout>
  );
};

export const query = graphql`
  query($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        ...CardListFragment
      }
    }
  }
`;

export default TagTemplate;
